<template>
  <div>
    <div class="vx-row">
      <div class="vx-col mb-5 sm:w-2/3 md:w-2/5 w-full">
        <vue-dropzone id="dropzone013" ref="uploadProImagesDrop"
            :use-custom-slot="true" :maxFiles="20" :maxFilesize="900"
            acceptedFiles="image/*,.webp,.gif">
            <div class="needsclick m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
              <h4 class="mb-2">Drop files here or click to upload.</h4>
              <span class="text-muted font-13">
                (upload image files)
              </span>
            </div>
        </vue-dropzone>
      </div>
    </div>
    <div class="flex flex-wrap justify-end items-center mt-5">
        <vs-button type="filled" @click.prevent="createPortfolio()" class="mr-4">Upload</vs-button>
        <vs-button class="mt-2" type="border" @click="clearAll()" color="success">Cancel</vs-button>
    </div>
  </div>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default {
  components:{
    VueDropzone
  },
  methods: {
    createPortfolio(){
      if(!this.collectFiles().length){this.alertError("Portfolio Image is Required"); return;}
      let formData = new FormData()
      this.collectFiles().forEach((item, index) => formData.append('image_'+index, item))
      this.$vs.loading({color:'#7d0c3f'})
      this.$http.post(`/portfolios/${this.$route.params.productId}/create`,formData)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/SET_PORTFOLIOS", response.data.data)
          this.clearAll()
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.uploadProImagesDrop){
        arrafile = [...this.$refs.uploadProImagesDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    clearAll(){
      if(this.$refs.uploadProImagesDrop){this.$refs.uploadProImagesDrop.removeAllFiles();}
      this.$emit('closeModal');
    },
  },
}
</script>
