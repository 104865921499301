<template>
  <div>
    <div class="mb-4">
      <product-attributes />
    </div>
    <div class="mb-4">
      <product-addons />
    </div>
    <div class="mb-4">
      <product-faqs />
    </div>
    <div class="mb-4">
      <product-hiws />
    </div>
    <div class="">
      <product-reviews />
    </div>

  </div>
</template>

<script>

import ProductFaqs from "@/components/product/ProductFaqs.vue"
import ProductAddons from "@/components/product/ProductAddons.vue"
import ProductAttributes from "@/components/product/ProductAttributes.vue"
import ProductHiws from '@/components/product/ProductHiws.vue'
import ProductReviews from '@/components/product/ProductReviews.vue'

export default {
  components: {
    ProductFaqs,
    ProductAddons,
    ProductAttributes,
    ProductHiws,
    ProductReviews
  },
}
</script>
