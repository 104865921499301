<template>
  <vx-card no-shadow>
    <vs-list>
      <vs-list-header icon-pack="feather" icon="icon-layers" title="Product how it works">
      </vs-list-header>
    </vs-list>
    <vs-collapse type="default" class="p-0" accordion>
      <vs-collapse-item v-for="(item, iIndex) in items" :key="iIndex">
        <div slot="header">{{item.title}}</div>
        <div>
          <div class="pb-3">
            <img :src="absoluteUrl(item.image)" class="w-full sm:w-1/3 md:w-1/4" />
          </div>
          <div>
            <p>{{item.features.split('|').join(", ")}}</p>
          </div>
        </div>
        <div class="flex justify-end">
          <vs-button radius color="dark" @click="editItem(item)"  class="inline" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
          <vs-button radius color="danger" @click="deleteItem(item.id)" class="inline" type="border" icon-pack="feather" icon="icon-trash"></vs-button>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="mt-2 mr-2" @click="popupClone = true;" color="warning" size="small">Copy from</vs-button>
      <vs-button class="mt-2" @click="popupAddItem = true; editMode = false " size="small">Add step</vs-button>
    </div>
    <vs-popup class="holamundo" @close="initializeAll"
     :title="editMode ? 'Edit step':'Add new step'" :active.sync="popupAddItem">
      <product-hiws-form v-if="popupAddItem"
        :editItem="showItem"
        :editMode="editMode"
        @closeMe="initializeAll"/>
    </vs-popup>

    <vs-popup class="holamundo" @close="initializeAll"
     title="Select product to copy from" 
     :active.sync="popupClone">
       <select v-model="cloneFrom"
          class="form-select mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, dpIndex) in products" :key="dpIndex"
          :value="dPro.id" >{{dPro.name}}</option>
      </select>
      <vs-button class="mt-2" @click="cloneNow()" size="small">Clone</vs-button>
    </vs-popup>
  </vx-card>
</template>

<script>
import ProductHiwsForm from "@/components/forms/ProductHiwsForm.vue"

export default {
  components:{
    ProductHiwsForm
  },
  data() {
    return {
      showItem: null,
      popupAddItem: false,
      editMode: false,
      cloneFrom: null,
      popupClone: false
    }
  },
  computed: {
    items(){
      return this.$store.state.productList.product.how_it_works
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    editItem(item){
      this.editMode = true
      this.showItem =  {...item}
      this.popupAddItem = true
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete this step!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type:'sound'})
      this.$http.delete(`/how-it-works/${itemId}/delete`)
      .then((response) =>{
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/DELETE_HIWS", itemId)
        }
      })
    },
    cloneNow(){
      if(!this.cloneFrom){return}
      let data = {from: this.cloneFrom, to: this.$route.params.productId}
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post('/how-it-works/clone', data)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
          this.initializeAll()
        }
      })
    },
    initializeAll(){
      this.showItem = null
      this.editMode = false
      this.popupAddItem = false
      this.popupClone = false;
      this.cloneFrom = null
    },
  },
}
</script>


<style type="text/css">
.vs-collapse-item--header {
    font-size: 1rem;
    padding: 0.5rem 1rem;
}
</style>
