<template>
  <vx-card no-shadow>

    <form>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input label="Title" placeholder="title" name="title" v-model="data.meta_title" class="w-full" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <div class="vx-col w-full mt-5">
            <label class="text-sm">Meta Keywords</label>
            <vs-textarea class="w-full" rows="2" name="keywords" v-validate="'max:1000'" counter="1000" label="Write keywords" :counter-danger.sync="counterDanger" v-model="data.meta_keys" />
              <span class="text-danger text-sm"  v-show="errors.has('keywords')">{{ errors.first('keywords') }}
              </span>
        </div>
        <div class="vx-col w-full mt-4">
            <label class="text-sm">Meta Descriptions</label>
            <vs-textarea class="w-full" rows="2" name="meta_desc" v-validate="'max:1000'" counter="1000" label="Write description" :counter-danger.sync="counterDanger" v-model="data.meta_desc" />
              <span class="text-danger text-sm"  v-show="errors.has('meta_desc')">{{ errors.first('meta_desc') }}
              </span>
        </div>
        <div class="vx-col w-full mt-4">
            <label class="text-sm">Main Product Descriptions</label>
            <quill-editor v-model="data.description"></quill-editor>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-col w-full my-5">
        <div class="vx-row py-5 items-center">
          <div class="vx-col w-1/2 sm:w-1/5">
            <h6 class="bg-black text-white p-2 rounded">Other details</h6>
          </div>
          <div class="vx-col w-1/2 sm:w-4/5 ">
            <hr class="" />
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <label class="text-sm">Short Desc</label>
        <vs-textarea class="w-full" rows="2" name="short_desc" v-model="data.short_desc" v-validate="'max:1000'" counter="1000" label="Write short Description" :counter-danger.sync="counterDanger"  />
          <span class="text-danger text-sm"  v-show="errors.has('short_desc')">{{ errors.first('short_desc') }}
          </span>
      </div>
      <!-- <div class="vx-col w-full mt-5">
        <label class="text-sm"> <span class="text-danger">N/B</span>: Services Details (Separate each with '#' and title with '|')</label>
        <vs-textarea class="w-full increase-vs-textarea-font" name="details" label="Write Service Details" rows="10" v-model="data.details" />
          <span class="text-danger text-sm"  v-show="errors.has('details')">{{ errors.first('details') }}
        </span>
      </div> -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button type="filled" size="small" @click.prevent="updateProduct" class="mt-2 block">Update</vs-button>
        <vs-button class="ml-4 mt-2" size="small" type="border" @click="fetchProduct()" color="success">Reset Data</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  // require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      data: {},
      counterDanger: false,
      editorOption: {
        theme: 'snow',
        placeholder: "Enter Page Description",
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet'}],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
          ]
        }
      },
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    }
  },
  methods: {
    updateProduct() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({color: "#444", type: "sound"})
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/products/${this.product.id}/update-metas`,formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit("productList/UPDATE_DESCRIPTION", response.data.data)
            }
           })
        }
      })
    },
    fetchProduct() {
      if(this.$route.params.productId < 1 ) {return}
      this.$store.dispatch("productList/fetchProduct",this.product.id)
      .then((response) => {
        if(response.data.success){
          this.patchProduct()
        }
      })
    },
    patchProduct() {
      if(this.product.description){
        this.data = {
          meta_title: this.product.description.meta_title,
          meta_keys: this.product.description.meta_keys,
          meta_desc: this.product.description.meta_desc,
          description: this.product.description.description,
          details: this.product.description.details,
          short_desc: this.product.description.short_desc
        }
      }
    },
  },
  created(){
    this.patchProduct()
  },
}
</script>
