<template>
 <form>
    <div class="vx-row">
        <div class="vx-col w-full">
            <vs-input v-validate="{ required: true}" label="Title *" placeholder="Enter title" name="title"
             v-model="data.title" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <div class="vx-col mt-5 w-full">
            <label class="text-sm"> <span class="text-danger">N/B</span>: (Separate each with ' | ')</label>
            <vs-textarea label="List features" class="w-full" name="features"
             v-model="data.features" />
        </div>
        <div class="vx-col mt-5 w-full">
            <h4 class="mb-2">Step Image</h4>
            <vue-dropzone id="proUploadDrop" ref="hiwsImageUploadDrop"
                :use-custom-slot="true" :maxFiles="1" :maxFilesize="200"
                acceptedFiles="image/*,.webp">
                <div class="needsclick m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                <h4 class="mb-2">Upload File or Drop file</h4>
                <p> (Ensure unique dimensions)</p>
                </div>
            </vue-dropzone>
        </div>
    </div>
    <vs-button class="ml-auto mt-4" @click.prevent="editMode ? update(): create()" size="small">{{editMode ? 'Update':'Submit'}}</vs-button>
</form>
</template>

<script>
import VueDropzone from "@/components/VueDropzone.vue";

export default {
    components:{
        VueDropzone
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                title: "",
                features: "",
            },
        }
    },
    methods:{
        create(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    if(!this.collectFiles().length){ this.alertError("An image file is required"); return;}
                    let formData = new FormData()
                    formData.append('image_file', this.collectFiles()[0])
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/how-it-works/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_HIWS', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    if(this.collectFiles().length){
                        formData.append('image_file', this.collectFiles()[0])
                    }
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/how-it-works/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_HIWS', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        collectFiles(){
            var arrafile = [];
            if(this.$refs.hiwsImageUploadDrop){
                arrafile = [...this.$refs.hiwsImageUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>